class Letter {
  constructor (letter, state, correctLetter) {
    this.letter = letter;
    this.state = state;
    this.correctLetter = correctLetter;
  }
}

export const LETTER_STATE = {
  CORRECT: 'CORRECT',
  CORRECT_LETTER: 'CORRECT_LETTER',
  EMPTY: 'EMPTY',
  INCORRECT: 'INCORRECT',
  INCORRECT_FINAL: 'INCORRECT_FINAL',
  INVALID: 'INVALID',
  NON_EMPTY: 'NON_EMPTY'
};

export default Letter;
