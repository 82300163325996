class Key {
  constructor (key, state) {
    this.key = key;
    this.state = state;
  }
}

export const KEY_STATE = {
  NOT_GUESSED: 'NOT_GUESSED',
  INCORRECT: 'INCORRECT',
  CORRECT_LETTER: 'CORRECT_LETTER',
  CORRECT: 'CORRECT'
};

export default Key;
