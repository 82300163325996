import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../shared/button/Button';
import styles from './FeatureRequest.module.scss'; ;

const FeatureRequest = (props) => {
  const {
    disabled,
    featureRequest,
    firstEntry,
    onComplete,
    onDelete
  } = props;

  const dateToDisplay = new Date(Date.parse(featureRequest.date)).toLocaleString();

  return (
    <div className={`${styles['feature-request']} ${firstEntry ? styles['first-entry'] : ''}`}>
      <div className={`${styles.info} ${featureRequest.completed ? styles.completed : ''}`}>
        <div className={styles.field}>
          <span className={styles.title}>Name: </span>
          {featureRequest.userName}
        </div>
        <div className={styles.field}>
          <span className={styles.title}>Date: </span>
          {dateToDisplay}
        </div>
        <div className={styles.field}>
          <div className={styles.title}>Feature Request:</div>
          <div>{featureRequest.featureRequestText}</div>
        </div>
      </div>
      <div className={styles['action-buttons']}>
        <Button
          className={styles['complete-button']}
          success={featureRequest.completed}
          disabled={disabled}
          onClick={onComplete}
        >
          { featureRequest.completed ? 'COMPLETED' : 'COMPLETE' }
        </Button>
        <Button
          className={styles['delete-button']}
          disabled={disabled}
          onClick={onDelete}
        >
          DELETE
        </Button>
      </div>
    </div>
  );
};

FeatureRequest.propTypes = {
  disabled: PropTypes.bool,
  featureRequest: PropTypes.object,
  firstEntry: PropTypes.bool,
  onComplete: PropTypes.func,
  onDelete: PropTypes.func
};

export default FeatureRequest;
