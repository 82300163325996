import React, { useContext, useEffect, useState } from 'react';
import { updateDocumentTitle } from '../../../shared/utils';
import { AuthContext } from '../../../shared/context/auth-context';
import FeatureRequest from './FeatureRequest';
import styles from './FeatureRequests.module.scss';

const FeatureRequests = () => {
  const authContext = useContext(AuthContext);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [featureRequests, setFeatureRequests] = useState([]);
  const [noFeatureRequestsMessage, setNoFeatureRequestsMessage] = useState('Loading...');

  const markFeatureRequestAsComplete = async (id, completed = true) => {
    setRequestInProgress(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/feature-request/${id}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authContext.token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ completed })
      }
    );
    const responseData = await response.json();

    if (responseData.isNotAdmin) {
      setFeatureRequests([]);
      setNoFeatureRequestsMessage('Wow, you\'re sneaky. But still, nice try.');
    } else if (responseData.message) {
      setFeatureRequests([]);
      setNoFeatureRequestsMessage(responseData.message);
    } else if (responseData.featureRequests) {
      setFeatureRequests(responseData.featureRequests);
    }
    setRequestInProgress(false);
  };

  const deleteFeatureRequest = async (id) => {
    setRequestInProgress(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/feature-request/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authContext.token}`
        }
      }
    );
    const responseData = await response.json();

    if (responseData.isNotAdmin) {
      setFeatureRequests([]);
      setNoFeatureRequestsMessage('Wow, you\'re sneaky. But still, nice try.');
    } else if (responseData.message) {
      setFeatureRequests([]);
      setNoFeatureRequestsMessage(responseData.message);
    } else if (responseData.featureRequests) {
      setFeatureRequests(responseData.featureRequests);
    }
    setRequestInProgress(false);
  };

  useEffect(() => {
    async function fetchFeatureRequests () {
      if (!authContext.user || !authContext.token) {
        setFeatureRequests([]);
        return;
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/feature-requests`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authContext.token}`
          }
        }
      );
      const responseData = await response.json();

      if (responseData.isNotAdmin) {
        setNoFeatureRequestsMessage('Wow, you\'re sneaky. But still, nice try.');
      } else if (responseData.message) {
        setNoFeatureRequestsMessage(responseData.message);
      } else if (responseData.featureRequests) {
        setFeatureRequests(responseData.featureRequests);
      }
    }

    updateDocumentTitle('Feature Requests');
    if (!authContext.user || !authContext.user.isAdmin) {
      setNoFeatureRequestsMessage('Wait, you\'re no admin. Nice try!');
      return;
    }

    fetchFeatureRequests();
  }, [authContext.user, authContext.token]);

  return (
    <div className={styles['feature-requests']}>
      <div className={styles['feature-requests__title']}>
        Feature Requests
      </div>
      <div className={styles['feature-requests__body']}>
        {featureRequests.length
          ? featureRequests.map((featureRequest, index) => {
            return (
            <FeatureRequest
              key={featureRequest.id}
              featureRequest={featureRequest}
              firstEntry={index === 0}
              disabled={requestInProgress}
              onComplete={() => markFeatureRequestAsComplete(featureRequest.id, !featureRequest.completed)}
              onDelete={() => deleteFeatureRequest(featureRequest.id)}
            />
            );
          })
          : noFeatureRequestsMessage}
      </div>
    </div>
  );
};

export default FeatureRequests;
