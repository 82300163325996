import React, { useEffect } from 'react';
import { updateDocumentTitle } from '../../shared/utils';
import styles from './GuestBook.module.scss';

const GuestBook = () => {
  useEffect(() => {
    updateDocumentTitle('Guestbook');
  }, []);

  return (
        <div className={styles['guest-book']}>
            <div className={styles['guest-book__title']}>
                Guest Book
            </div>
            <div className={styles['guest-book__body']}>
                Under construction, check back in 2028 or so
            </div>
        </div>
  );
};

export default GuestBook;
