import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../../shared/context/main-context';
import PropTypes from 'prop-types';
import styles from './MenuLink.module.scss';

const MenuLink = (props) => {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const { isSelected, path, title } = props;

  const navigateToPage = (path) => {
    navigate(path);
    mainContext.setMenuPaneOpen(false);
  };

  return (
        <button
            className={`${styles['page-link']} ${isSelected ? styles.selected : ''}`}
            onClick={() => navigateToPage(path)}>
            { title }
        </button>
  );
};

MenuLink.propTypes = {
  isSelected: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string
};

export default MenuLink;
