import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import openSocket from 'socket.io-client';
import { cloneDeep } from 'lodash';
import Board from './board/Board';
import Keyboard from './keyboard/Keyboard';
import DelayModal from './modals/DelayModal';
import EndModal from './modals/EndModal';
import { GAME_MODES, HARD_EMOJI, defaultPlayerMetadata } from './utils';
import Button from '../shared/button/Button';
import Letter, { LETTER_STATE } from '../../classes/steword/Letter';
import { MainContext } from '../../shared/context/main-context';
import { StewordContext } from '../../shared/context/steword-context';
import { useSteword } from '../../shared/hooks/steword-hook';
import { updateDocumentTitle } from '../../shared/utils';
import AnnouncementModal from './modals/AnnouncementModal';
import HardModeModal from './modals/HardModeModal';
import styles from './Steword.module.scss';

const Steword = (props) => {
  const navigate = useNavigate();
  const [currentGuess, setCurrentGuess] = useState(1);
  const [guesses, setGuesses] = useState([[]]);
  const [guessedLetters, setGuessedLetters] = useState({});
  const [guessInFlight, setGuessInFlight] = useState(false);
  const [showDelayModal, setShowDelayModal] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [gameLost, setGameLost] = useState(false);
  const [showResultsButton, setShowResultsButton] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [showHardModeModal, setShowHardModeModal] = useState(false);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [playerMetadata, setPlayerMetadata] = useState({});
  const [announcements, setAnnouncements] = useState([]);

  const {
    menuPaneOpen,
    userPaneOpen,
    pushToast
  } = useContext(MainContext);
  const {
    gameMode,
    resultsDisplayed,
    socket,
    setGameMode,
    setResultsDisplayed,
    setSocket
  } = useSteword({ gameMode: props.mode || GAME_MODES.easy });

  const gameOver = gameWon || gameLost;

  const gamePaused = gameOver ||
    guessInFlight ||
    menuPaneOpen ||
    userPaneOpen ||
    showHardModeModal;

  const initializeGuesses = (pastGuesses) => {
    const guessArray = [[]];
    for (let i = 0; i < 6; i++) {
      guessArray[i] = [];
      for (let j = 0; j < 5; j++) {
        let letter = '';
        let state = LETTER_STATE.EMPTY;
        let correctLetter = '';

        if (pastGuesses) {
          letter = pastGuesses[i][j].letter;
          state = pastGuesses[i][j].state;
          correctLetter = pastGuesses[i][j].correctLetter;
        }
        guessArray[i][j] = new Letter(letter, state, correctLetter);
      }
    }

    return guessArray;
  };

  const formatLocalStorageKey = (key) => {
    return `steword-${gameMode}-${key}`;
  };

  const isGuess = (key, target) => {
    if (target.tagName && target.tagName !== 'BODY') {
      return false;
    }

    const charCode = key.charCodeAt();
    return charCode === 69;
  };

  const isBackspace = (key) => {
    return key === 'Backspace';
  };

  const updatePlayerMetadata = (win) => {
    const updatedPlayerMetadata = cloneDeep(playerMetadata);

    if (win) {
      const dateYesterday = new Date();
      dateYesterday.setDate(dateYesterday.getDate() - 1);

      if (updatedPlayerMetadata.lastWin === dateYesterday.toDateString()) {
        updatedPlayerMetadata.currentStreak++;
      } else {
        updatedPlayerMetadata.currentStreak = 1;
      }

      updatedPlayerMetadata.maxStreak = Math.max(
        updatedPlayerMetadata.currentStreak,
        updatedPlayerMetadata.maxStreak
      );
      updatedPlayerMetadata.lastWin = new Date().toDateString();
      updatedPlayerMetadata.winningGuess[currentGuess]++;
    } else {
      updatedPlayerMetadata.currentStreak = 0;
      updatedPlayerMetadata.winningGuess['X']++;
    }

    localStorage.setItem(formatLocalStorageKey('player-metadata'), JSON.stringify(updatedPlayerMetadata));
    setPlayerMetadata(updatedPlayerMetadata);

    let userName = '';
    if (localStorage.getItem('steword-submitted-score')) {
      userName = JSON.parse(localStorage.getItem('steword-submitted-score')).name;
    }
    fetch(
      `${process.env.REACT_APP_API_URL}/steword/playerMetadata`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: localStorage.getItem('steword-temporary-user-id'),
          userName,
          playerMetadata: updatedPlayerMetadata,
          gameMode
        })
      }
    );
  };

  const endGame = (win) => {
    setGameWon(win);
    setGameLost(!win);

    localStorage.setItem(formatLocalStorageKey('game-won'), win);
    localStorage.setItem(formatLocalStorageKey('game-lost'), !win);

    updatePlayerMetadata(win);

    setTimeout(() => {
      setShowResultsModal(true);
      setShowResultsButton(true);
    }, 1500);
  };

  const setGuessInvalid = (invalid) => {
    const tempGuesses = [...guesses];
    for (let i = 0; i < 5; i++) {
      const curLetter = tempGuesses[currentGuess - 1][i];
      curLetter.state = invalid ? LETTER_STATE.INVALID : LETTER_STATE.NON_EMPTY;
    }

    setGuesses(tempGuesses);

    if (invalid) {
      setTimeout(() => {
        setGuessInvalid(false);
      }, 250);
    }
  };

  const performGuess = async () => {
    if (gamePaused) {
      return;
    }

    for (let i = 0; i < 5; i++) {
      const curLetter = guesses[currentGuess - 1][i];
      if (curLetter.letter === '') {
        return;
      }
    }

    const guessArray = guesses[currentGuess - 1];
    const guessWord = guessArray.reduce((word, letter) => {
      return word + letter.letter;
    }, '');

    setGuessInFlight(true);
    const guessTimeout = setTimeout(() => setShowDelayModal(true), 1000);

    const currentDate = new Date().toLocaleDateString('en-US');
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/steword/guess?currentDate=${currentDate}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guessWord,
          currentGuess,
          gameMode
        })
      }
    );

    const json = await response.json();
    if (json.error) {
      setGuessInvalid(true);
      setGuessInFlight(false);
      setShowDelayModal(false);
      clearTimeout(guessTimeout);
      return;
    }

    const responseArray = json.responseArray;

    const tempGuesses = [...guesses];
    let winningGuess = true;
    for (let i = 0; i < 5; i++) {
      const guessLetter = responseArray[i].guessLetter;
      const state = responseArray[i].state;

      tempGuesses[currentGuess - 1][i] = new Letter(guessLetter, state);

      if (!guessedLetters[guessLetter]) {
        guessedLetters[guessLetter] = state;
      } else if (state === LETTER_STATE.CORRECT) {
        guessedLetters[guessLetter] = state;
      } else if (guessedLetters[guessLetter] === LETTER_STATE.INCORRECT) {
        guessedLetters[guessLetter] = state;
      }

      if (state !== LETTER_STATE.CORRECT) {
        winningGuess = false;
        if (currentGuess === 6) {
          tempGuesses[currentGuess - 1][i].correctLetter =
            responseArray[i].correctLetter;
          tempGuesses[currentGuess - 1][i].state = LETTER_STATE.INCORRECT_FINAL;
        }
      }
    }

    setGuessInFlight(false);
    setShowDelayModal(false);
    clearTimeout(guessTimeout);

    if (winningGuess) {
      endGame(true);
    } else if (currentGuess >= 6) {
      endGame(false);
    }

    setGuessedLetters(guessedLetters);
    localStorage.setItem(
      formatLocalStorageKey('guessed-letters'),
      JSON.stringify(guessedLetters)
    );

    setGuesses(tempGuesses);
    localStorage.setItem(formatLocalStorageKey('guesses'), JSON.stringify(tempGuesses));

    setCurrentGuess(currentGuess + 1);
    localStorage.setItem(
      formatLocalStorageKey('current-guess'),
      JSON.stringify(currentGuess + 1)
    );
  };

  const performBackspace = () => {
    if (gamePaused) {
      return;
    }

    const tempGuesses = [...guesses];
    if (tempGuesses[currentGuess - 1][0].letter === '') {
      return;
    }

    for (let i = 1; i < 5; i++) {
      const curLetter = guesses[currentGuess - 1][i];
      if (curLetter.letter !== '') {
        continue;
      }

      tempGuesses[currentGuess - 1][i - 1] = new Letter('', LETTER_STATE.EMPTY);
      setGuesses(tempGuesses);
      return;
    }

    tempGuesses[currentGuess - 1][4] = new Letter('', LETTER_STATE.EMPTY);
    setGuesses(tempGuesses);
  };

  const addLetterToGuess = (key) => {
    if (gamePaused) {
      return;
    } else if (key.length > 1) {
      return;
    }

    const charCode = key.charCodeAt();

    if (charCode >= 65 && charCode <= 90) {
      key = String.fromCharCode(charCode);
    } else if (charCode >= 97 && charCode <= 122) {
      key = String.fromCharCode(charCode - 32);
    } else {
      return;
    }

    const tempGuesses = [...guesses];
    for (let i = 0; i < 5; i++) {
      const curLetter = guesses[currentGuess - 1][i];
      if (curLetter.letter !== '') {
        continue;
      }

      const letterStatus = LETTER_STATE.NON_EMPTY;
      tempGuesses[currentGuess - 1][i] = new Letter(key, letterStatus);

      setGuesses(tempGuesses);
      return;
    }
  };

  const handleKeyDown = (event) => {
    if (gamePaused) {
      return;
    }

    const key = event.key;
    const { target } = event;
    if (isGuess(key, target)) {
      performGuess();
    } else if (isBackspace(key)) {
      performBackspace();
    } else {
      addLetterToGuess(key);
    }
  };

  const initializeGame = () => {
    let pastDate = JSON.parse(localStorage.getItem(formatLocalStorageKey('date')));
    if (pastDate) {
      pastDate = new Date(Date.parse(pastDate));
    }

    let playerMetadata = JSON.parse(localStorage.getItem(formatLocalStorageKey('player-metadata')));
    if (!playerMetadata) {
      playerMetadata = defaultPlayerMetadata;
    } else if (Object.hasOwn(playerMetadata.winningGuess, 'lose')) {
      playerMetadata.winningGuess['X'] = playerMetadata.winningGuess['lose'];
      delete playerMetadata.winningGuess['lose'];
      localStorage.setItem(formatLocalStorageKey('player-metadata'), JSON.stringify(playerMetadata));
    }
    setPlayerMetadata(playerMetadata);

    const seenHardModeModal = localStorage.getItem(formatLocalStorageKey('seen-hard-mode-modal'));
    const today = new Date();
    if (
      !pastDate ||
      !(
        pastDate.getDate() === today.getDate() &&
        pastDate.getMonth() === today.getMonth() &&
        pastDate.getFullYear() === today.getFullYear()
      )
    ) {
      if (gameMode === GAME_MODES.hard && !seenHardModeModal) {
        setShowHardModeModal(true);
      } else {
        setShowHardModeModal(false);
      }
      localStorage.setItem(
        formatLocalStorageKey('date'),
        JSON.stringify(today.toDateString())
      );
      localStorage.removeItem(formatLocalStorageKey('current-guess'));
      localStorage.removeItem(formatLocalStorageKey('guesses'));
      localStorage.removeItem(formatLocalStorageKey('guessed-letters'));
      localStorage.removeItem(formatLocalStorageKey('game-won'));
      localStorage.removeItem(formatLocalStorageKey('game-lost'));

      const submittedScore = JSON.parse(localStorage.getItem('steword-submitted-score'));
      if (submittedScore && submittedScore[gameMode]) {
        submittedScore[gameMode].submitted = false;
        localStorage.setItem('steword-submitted-score', JSON.stringify(submittedScore));
      }

      const newGuesses = initializeGuesses();
      localStorage.setItem(formatLocalStorageKey('guesses'), JSON.stringify(newGuesses));
      setGuesses(newGuesses);

      localStorage.setItem(
        formatLocalStorageKey('current-guess'),
        JSON.stringify(1)
      );
      setCurrentGuess(1);

      localStorage.setItem(
        formatLocalStorageKey('guessed-letters'),
        JSON.stringify({})
      );
      setGuessedLetters({});

      localStorage.setItem(formatLocalStorageKey('game-won'), false);
      localStorage.setItem(formatLocalStorageKey('game-lost'), false);
      setGameWon(false);
      setGameLost(false);
      setShowResultsButton(false);
      setShowResultsModal(false);
    } else {
      const pastCurrentGuess = JSON.parse(
        localStorage.getItem(formatLocalStorageKey('current-guess'))
      );
      const pastGuesses = JSON.parse(localStorage.getItem(formatLocalStorageKey('guesses')));
      const pastGuessedLetters = JSON.parse(
        localStorage.getItem(formatLocalStorageKey('guessed-letters'))
      );
      const pastGameWon = JSON.parse(localStorage.getItem(formatLocalStorageKey('game-won')));
      const pastGameLost = JSON.parse(
        localStorage.getItem(formatLocalStorageKey('game-lost'))
      );

      if (pastCurrentGuess === 1 && gameMode === GAME_MODES.hard && !seenHardModeModal) {
        setShowHardModeModal(true);
      } else {
        setShowHardModeModal(false);
      }

      const shouldShowResultsModal = (pastGameWon || pastGameLost) && !resultsDisplayed;

      setCurrentGuess(pastCurrentGuess);
      setGuesses(initializeGuesses(pastGuesses));
      setGuessedLetters(pastGuessedLetters);
      setGameWon(pastGameWon);
      setGameLost(pastGameLost);
      setShowResultsButton(pastGameWon || pastGameLost);
      setShowResultsModal(shouldShowResultsModal);
    }
  };

  const changeGameMode = () => {
    navigate(gameMode === GAME_MODES.easy ? '/steword/hard' : '/steword');
    window.location.reload(false);
  };

  const onHardModeModalClosed = () => {
    localStorage.setItem(formatLocalStorageKey('seen-hard-mode-modal'), true);
    setShowHardModeModal(false);
  };

  useEffect(() => {
    async function fetchGameData () {
      const currentDate = new Date().toLocaleString();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/steword/gameData?currentDate=${currentDate}`,
        { method: 'GET' }
      );
      const responseData = await response.json();
      const dismissedAnnouncements = localStorage.getItem('steword-dismissed-announcements') || [];
      setAnnouncements(responseData.activeAnnouncements.filter(announcement => {
        return !dismissedAnnouncements.includes(announcement.id);
      }));
    }

    async function createTemporaryUser () {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/steword/temporaryUser`,
        { method: 'GET' }
      );
      const { temporaryUserId } = await response.json();
      if (!temporaryUserId) {
        return;
      }

      localStorage.setItem('steword-temporary-user-id', temporaryUserId);
    }

    if (!localStorage.getItem('steword-temporary-user-id')) {
      createTemporaryUser();
    }

    fetchGameData();
    initializeGame();
    updateDocumentTitle('Steword');

    const socket = openSocket(process.env.REACT_APP_SOCKET_URL);
    socket.on('scoreSubmitted', newScore => {
      const toast = { zIndex: 21 };

      toast.content = (
        <span>
          <span className={styles['toast-player-name']}>
            {newScore.name}
          </span>
          {newScore.numberOfGuesses === 7
            ? <span> did not guess the word!</span>
            : (<span>{' scored '}
              <span className={styles['toast-player-name']}>
                {newScore.numberOfGuesses}
              </span>!
            </span>)}
        </span>
      );

      pushToast(toast);
    });
    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!announcements || !announcements.length) {
      setShowAnnouncementModal(false);
      return;
    }

    const dismissedAnnouncements = JSON.parse(localStorage.getItem('steword-dismissed-announcements'));
    if (!dismissedAnnouncements || !dismissedAnnouncements.length) {
      setShowAnnouncementModal(true);
      return;
    } else if (dismissedAnnouncements.includes(announcements[0].id)) {
      setShowAnnouncementModal(false);
      return;
    }

    setShowAnnouncementModal(true);
  }, [announcements]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const getGameTitle = () => {
    return (
      <React.Fragment>
        {gameMode === GAME_MODES.hard && (
          <span>{HARD_EMOJI} </span>
        )}
        <span className={styles.S}>S</span>
        <span className={styles.T}>T</span>
        <span className={styles.E}>E</span>
        <span className={styles.W}>W</span>
        <span className={styles.O}>O</span>
        <span className={styles.R}>R</span>
        <span className={styles.D}>D</span>
        {gameMode === GAME_MODES.hard && (
          <span> {HARD_EMOJI}</span>
        )}
      </React.Fragment>
    );
  };

  const onAnnouncementModalClosed = () => {
    const dismissedAnnouncements = JSON.parse(localStorage.getItem('steword-dismissed-announcements')) || [];
    dismissedAnnouncements.push(announcements[0].id);
    localStorage.setItem('steword-dismissed-announcements', JSON.stringify(dismissedAnnouncements));

    setShowAnnouncementModal(false);
    setAnnouncements(prevAnnouncements => {
      return prevAnnouncements.slice(1);
    });
  };

  const onResultsModalClosed = () => {
    setResultsDisplayed(true);
    setShowResultsModal(false);
  };

  return (
    <StewordContext.Provider
      value={{
        gameMode,
        resultsDisplayed,
        socket,
        setGameMode,
        setResultsDisplayed,
        setSocket
      }}
    >
      {showAnnouncementModal && (
        <AnnouncementModal
          announcement={announcements[0]}
          onClose={onAnnouncementModalClosed}
        />
      )}
      {showHardModeModal && (
        <HardModeModal
          onClose={onHardModeModalClosed}
        />
      )}
      {showDelayModal && <DelayModal />}
      {showResultsModal && (
        <EndModal
          win={gameWon}
          onClose={onResultsModalClosed}
          guesses={guesses}
          playerMetadata={playerMetadata}
        />
      )}
      <div className={styles.steword}>
        <div className={`${styles.title} ${!gameOver && styles.animate}`}>{getGameTitle()}</div>
        <Board
          guesses={guesses}
        />
        <div className={styles.results}>
          {showResultsButton && (
            <Button
              className={styles['results-button']}
              onClick={() => setShowResultsModal(true)}
            >
              SHOW RESULTS
            </Button>
          )}
          <button className={styles['change-mode-link']} onClick={changeGameMode}>
            {`Want to ${gameMode === GAME_MODES.easy ? 'try hard' : 'go back to easy'} mode?`}
          </button>
        </div>
        <Keyboard
          addLetterToGuess={addLetterToGuess}
          performGuess={performGuess}
          performBackspace={performBackspace}
          guessedLetters={guessedLetters}
        />
      </div>
    </StewordContext.Provider>
  );
};

Steword.propTypes = {
  mode: PropTypes.string
};

export default Steword;
