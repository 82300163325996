import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AuthContext } from '../shared/context/auth-context';
import { MainContext } from '../shared/context/main-context';
import { useMain } from '../shared/hooks/main-hook';
import { getThemeClass } from '../shared/utils';
import Steword from '../components/steword/Steword';
import GuestBook from '../components/guestbook/GuestBook';
import FeatureRequests from '../components/admin/feature-requests/FeatureRequests';
import Header from '../components/steword/Header';
import UserOptions from '../components/main/user-options/UserOptions';
import Login from '../components/main/login/Login';
import Menu from '../components/main/menu/Menu';
import mainPageStyles from './MainPage.module.scss';
import themeStyles from '../styles/themes.module.scss';
import Toast from '../components/main/Toast';

const MainPage = () => {
  const authContext = useContext(AuthContext);
  const {
    menuPaneOpen,
    userPaneOpen,
    theme,
    toasts,
    popToast,
    pushToast,
    setMenuPaneOpen,
    setUserPaneOpen,
    setTheme
  } = useMain();

  useEffect(() => {
    toasts.forEach(toast => {
      if (toast.active) return;

      setTimeout(() => {
        popToast(toast);
      }, toast.duration);
    });
  }, [toasts]);

  const closePanes = () => {
    setMenuPaneOpen(false);
    setUserPaneOpen(false);
  };

  return (
    <MainContext.Provider
      value={{
        menuPaneOpen,
        userPaneOpen,
        theme,
        toasts,
        popToast,
        pushToast,
        setMenuPaneOpen,
        setUserPaneOpen,
        setTheme
      }}
    >
      <div className={`${mainPageStyles['main-page']} ${themeStyles[getThemeClass(theme)]}`}>
        <div className={mainPageStyles['main-page__header']}>
          <Header />
        </div>
        <div className={mainPageStyles['main-page__body']}>
          <CSSTransition
            in={menuPaneOpen}
            unmountOnExit
            timeout={{ enter: 150, exit: 100 }}
            classNames={{
              enterActive: mainPageStyles['menu-enter-active'],
              enterDone: mainPageStyles['menu-enter-done'],
              exit: mainPageStyles['menu-exit'],
              exitActive: mainPageStyles['menu-exit-active']
            }}
          >
            <Menu />
          </CSSTransition>
          <CSSTransition
            in={userPaneOpen}
            unmountOnExit
            timeout={{ enter: 150, exit: 100 }}
            classNames={{
              enterActive: mainPageStyles['login-enter-active'],
              enterDone: mainPageStyles['login-enter-done'],
              exit: mainPageStyles['login-exit'],
              exitActive: mainPageStyles['login-exit-active']
            }}
          >
            {authContext.isLoggedIn ? <UserOptions /> : <Login />}
          </CSSTransition>
          {(menuPaneOpen || userPaneOpen) &&
            <div
              className={mainPageStyles['main-page__backdrop']}
              onClick={closePanes}
            />
          }
          { toasts.length > 0 && (
            <div className={mainPageStyles['toast-column']}>
              { toasts.map((toast, index) => (
                <Toast
                  key={index}
                  content={toast.content}
                  zIndex={toast.zIndex}
                />
              ))}
            </div>
          )}
          <Routes>
            <Route exact path="/guestbook" element={<GuestBook />} />
            <Route exact path="/steword" element={<Steword />} />
            <Route exact path="/steword/hard" element={<Steword mode="hard" />} />
            <Route exact path="/feature-requests" element={<FeatureRequests />} />
            <Route exact path="/" element={<Navigate replace to="/steword" />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </div>
    </MainContext.Provider>
  );
};

export default MainPage;
