import { createContext } from 'react';
import { GAME_MODES } from '../../components/steword/utils';

export const StewordContext = createContext({
  gameMode: GAME_MODES.easy,
  resultsDisplayed: false,
  socket: {},
  setGameMode: () => {},
  setResultsDisplayed: () => {},
  setSocket: () => {}
});
