import { LETTER_STATE } from '../../classes/steword/Letter';
import { THEMES } from '../../shared/constants';

export const formatResultsForDisplay = ({ guesses, theme, gameMode = GAME_MODES.easy }) => {
  let resultsStr = '';
  let endLoop = false;

  for (let i = 0; i < guesses.length; i++) {
    const curGuess = guesses[i];
    let rowResultsStr = '';

    for (let j = 0; j < curGuess.length; j++) {
      const curLetter = curGuess[j];

      if (curLetter.letter === '') {
        endLoop = true;
        break;
      }

      switch (curLetter.state) {
        case LETTER_STATE.CORRECT:
          rowResultsStr += String.fromCodePoint(gameMode === GAME_MODES.hard ? 0x1f7e5 : 0x1f7e9);
          break;
        case LETTER_STATE.CORRECT_LETTER:
          rowResultsStr += String.fromCodePoint(0x1f7e8);
          break;
        case LETTER_STATE.INCORRECT:
          rowResultsStr += String.fromCodePoint(theme === THEMES.DARK ? 0x2b1b : 0x2b1c);
          break;
        case LETTER_STATE.INCORRECT_FINAL:
          rowResultsStr += String.fromCodePoint(0x274c);
          break;
        default:
          break;
      }
    }

    if (endLoop) {
      break;
    }

    resultsStr += rowResultsStr + '\n';
  }

  return resultsStr;
};

export const getSavedNumberOfGuesses = (gameMode) => {
  let savedNumGuesses;
  if (JSON.parse(localStorage.getItem(`steword-${gameMode}-game-lost`))) {
    savedNumGuesses = 'X';
  } else {
    savedNumGuesses = (JSON.parse(localStorage.getItem(`steword-${gameMode}-current-guess`)) - 1).toString();
  }

  return savedNumGuesses;
};

export const GAME_MODES = {
  easy: 'easy',
  hard: 'hard'
};

export const HARD_EMOJI = String.fromCodePoint(0x1F479);

export const defaultPlayerMetadata = {
  lastWin: null,
  currentStreak: 0,
  maxStreak: 0,
  winningGuess: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    X: 0
  }
};
