import React from 'react';
import PropTypes from 'prop-types';
import Key, { KEY_STATE } from '../../../classes/steword/Key';
import KeyboardKey from './KeyboardKey';
import styles from './KeyboardRow.module.css';

const KeyboardRow = (props) => {
  let keyLetters;
  switch (props.rowPosition) {
    case KEYBOARD_ROWS.TOP:
      keyLetters = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
      break;
    case KEYBOARD_ROWS.MIDDLE:
      keyLetters = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
      break;
    case KEYBOARD_ROWS.BOTTOM:
    default:
      keyLetters = ['Enter', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Backspace'];
      break;
  }

  const rowKeys = keyLetters.map(
    (letter) =>
      new Key(letter, props.guessedLetters[letter] || KEY_STATE.NOT_GUESSED)
  );

  return (
    <div className={styles['keyboard-row']}>
      {rowKeys.map((key, index) => (
        <KeyboardKey
          key={index}
          keyboardKey={key}
          addLetterToGuess={props.addLetterToGuess}
          performGuess={props.performGuess}
          performBackspace={props.performBackspace}
        />
      ))}
    </div>
  );
};

KeyboardRow.propTypes = {
  row: PropTypes.string,
  rowPosition: PropTypes.string,
  addLetterToGuess: PropTypes.func,
  performGuess: PropTypes.func,
  performBackspace: PropTypes.func,
  guessedLetters: PropTypes.object
};

export const KEYBOARD_ROWS = {
  TOP: 'TOP',
  MIDDLE: 'MIDDLE',
  BOTTOM: 'BOTTOM'
};

export default KeyboardRow;
