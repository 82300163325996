import { createContext } from 'react';

export const AuthContext = createContext({
  isLoggedIn: false,
  user: {
    id: null,
    email: null,
    isAdmin: false
  },
  token: null,
  login: () => {},
  logout: () => {}
});
