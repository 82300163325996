import { createContext } from 'react';
import { THEMES } from '../constants';

export const MainContext = createContext({
  menuPaneOpen: false,
  userPaneOpen: false,
  theme: THEMES.DEFAULT,
  toasts: [],
  popToast: () => {},
  pushToast: () => {},
  setMenuPaneOpen: () => {},
  setUserPaneOpen: () => {},
  setTheme: () => {}
});
