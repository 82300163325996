import { THEMES } from './constants';

export const getThemeClass = (theme) => {
  switch (theme) {
    case THEMES.DARK:
      return 'dark-theme';
    case THEMES.DEFAULT:
    default:
      return 'default-theme';
  }
};

export const updateDocumentTitle = (page) => {
  document.title = page + ' | bstewart.io';
};
