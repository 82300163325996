import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MainContext } from '../../../shared/context/main-context';
import { StewordContext } from '../../../shared/context/steword-context';
import LeaderboardRow from './LeaderboardRow';
import Button from '../../shared/button/Button';
import Confetti from '../../../shared/other/confetti';
import { THEMES } from '../../../shared/constants';
import caret from '../../../res/caret.svg';
import caretDark from '../../../res/caret-dark.svg';
import { GAME_MODES, HARD_EMOJI } from '../utils';
import styles from './Leaderboard.module.scss';

const Leaderboard = (props) => {
  const { displayConfetti } = props;

  const [scores, setScores] = useState([]);
  const [answer, setAnswer] = useState('');
  const [userName, setUserName] = useState('');
  const [numGuesses, setNumGuesses] = useState(null);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [scoreSubmitted, setScoreSubmitted] = useState(false);
  const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(false);
  const [leaderboardDayOffset, setLeaderboardDayOffset] = useState(0);
  const [confetti] = useState(new Confetti());

  const { theme } = useContext(MainContext);
  const { gameMode, socket } = useContext(StewordContext);

  useEffect(() => {
    async function fetchLeaderboardData () {
      const currentDate = new Date().toLocaleDateString('en-US');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/steword/leaderboard?currentDate=${currentDate}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            gameMode,
            leaderboardDayOffset
          })
        }
      );
      const responseData = await response.json();

      setScores(responseData.scores);
      setAnswer(responseData.answer);
    }

    try {
      setIsLoadingLeaderboard(true);
      fetchLeaderboardData();

      let savedNumGuesses;
      if (JSON.parse(localStorage.getItem(`steword-${gameMode}-game-lost`))) {
        savedNumGuesses = 'X';
      } else {
        savedNumGuesses = (JSON.parse(localStorage.getItem(`steword-${gameMode}-current-guess`)) - 1).toString();

        if (!confetti.isConfettiRunning() && displayConfetti) {
          confetti.startConfetti(savedNumGuesses, gameMode);
        }
      }
      setNumGuesses(savedNumGuesses);

      const submittedScore = JSON.parse(localStorage.getItem('steword-submitted-score'));
      if (submittedScore) {
        if (submittedScore[gameMode]) {
          setScoreSubmitted(submittedScore[gameMode].submitted);
        }

        if (submittedScore.name) {
          setUserName(submittedScore.name);
          setSubmitEnabled(submittedScore.name.length > 0);
        }
      }

      setIsLoadingLeaderboard(false);
    } catch (err) {
      console.log(err);
      setIsLoadingLeaderboard(true);
    }

    socket.on('leaderboardUpdated', ({ scores }) => {
      setScores(scores);
    });

    return () => {
      confetti.stopConfetti();
    };
  }, [confetti, gameMode, leaderboardDayOffset, displayConfetti]);

  const getFormattedScoresForDisplay = () => {
    const formattedScores = [...scores];

    while (formattedScores.length < 3) {
      formattedScores.push({ name: null, numberOfGuesses: null });
    }

    return formattedScores;
  };

  const submitScore = async () => {
    if (scoreSubmitted || !submitEnabled || isLoadingLeaderboard) return;

    setIsLoadingLeaderboard(true);
    const currentDate = new Date().toLocaleDateString('en-US');
    const numberOfGuesses = numGuesses === 'X' ? 7 : numGuesses;
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/steword/submitScore?currentDate=${currentDate}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: userName,
          numberOfGuesses,
          guesses: localStorage.getItem(`steword-${gameMode}-guesses`),
          gameMode
        })
      }
    );
    const responseData = await response.json();
    const prevSubmittedScore = JSON.parse(localStorage.getItem('steword-submitted-score'));
    localStorage.setItem('steword-submitted-score', JSON.stringify({
      ...prevSubmittedScore,
      name: userName,
      [gameMode]: {
        submitted: true
      }
    }));

    setScores(responseData.scores);
    setScoreSubmitted(true);
    setSubmitEnabled(false);
    setIsLoadingLeaderboard(false);

    socket.emit('scoreSubmitted', {
      name: userName,
      numberOfGuesses
    });
  };

  const userNameChangeHandler = ({ target }) => {
    const newUserName = target.value;

    setSubmitEnabled(newUserName.length > 0);
    setUserName(target.value);
  };

  const getLeaderboardTitle = () => {
    let leaderboardDisplayDate = 'Today';
    if (leaderboardDayOffset !== 0) {
      const leaderboardDate = new Date();
      leaderboardDate.setDate(leaderboardDate.getDate() - leaderboardDayOffset);
      leaderboardDisplayDate = leaderboardDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }

    const title = `LEADERBOARD (${leaderboardDisplayDate})`;
    if (gameMode === GAME_MODES.hard) {
      return `${HARD_EMOJI} ${title} ${HARD_EMOJI}`;
    }

    return title;
  };

  const incrementLeaderboardDayOffset = () => {
    if (leaderboardDayOffset >= 7) return;
    setLeaderboardDayOffset(prevLeaderboardDayOffset => prevLeaderboardDayOffset + 1);
  };

  const decrementLeaderboardDayOffset = () => {
    if (leaderboardDayOffset <= 0) return;
    setLeaderboardDayOffset(prevLeaderboardDayOffset => prevLeaderboardDayOffset - 1);
  };

  return (
    <div className={styles.leaderboard}>
      <div className={styles['leaderboard-header']}>
        {!isLoadingLeaderboard && (
          <div
            className={`${styles['leaderboard-button__back']} ${leaderboardDayOffset >= 7 && styles.disabled}`}
            onClick={incrementLeaderboardDayOffset}
          >
            <img
              className={styles['back-icon']}
              src={theme === THEMES.DARK ? caretDark : caret}
              alt="Previous day's leaderboard"
              height="16"
              width="16"
            />
            <span>Prev</span>
          </div>
        )}
        <div className={styles['leaderboard-title']}>
          {getLeaderboardTitle()}
        </div>
        {!isLoadingLeaderboard && (
          <div
            className={`${styles['leaderboard-button__forward']} ${leaderboardDayOffset <= 0 && styles.disabled}`}
            onClick={decrementLeaderboardDayOffset}
          >
            <span>Next</span>
            <img
              className={styles['forward-icon']}
              src={theme === THEMES.DARK ? caretDark : caret}
              alt="Previous day's leaderboard"
              height="16"
              width="16"
            />
          </div>
        )}
      </div>
      { answer && leaderboardDayOffset > 0 && (
        <div className={styles['leaderboard-answer']}>
          <span>Answer: </span>
          <span className={styles['answer-word']}>{answer}</span>
        </div>
      )}
      <div className={styles['leaderboard-scores-list']}>
        {getFormattedScoresForDisplay().map((score, index) => (
          <LeaderboardRow
            key={index}
            index={index}
            score={score}
            isLoadingLeaderboard={isLoadingLeaderboard}
          />
        ))}
      </div>
      { gameMode === GAME_MODES.hard && (
        <div className={styles['leaderboard-link']}>
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={`https://www.oed.com/search/dictionary/?scope=Entries&q=${answer}`}>
            What the heck does {answer} mean?
          </a>
        </div>
      )}
      <div className={styles['leaderboard-score-submit']}>
        <div className={styles['leaderboard-score-submit__text']}>
          { !scoreSubmitted ? 'Submit your score to the daily leaderboard:' : 'Play again tomorrow!' }
        </div>
        <div className={styles['leaderboard-score-submit__form']}>
          <div className={styles['leaderboard-score-submit__label']}>
            {scoreSubmitted ? '' : 'Name:'}
          </div>
          {!scoreSubmitted
            ? <input
              className={styles['leaderboard-score-submit__input']}
              type='text'
              value={userName}
              onChange={userNameChangeHandler}
            />
            : <div className={styles['leaderboard-score-submit__label']}>
              {userName}
            </div>
          }
          <div className={styles['leaderboard-score-submit__label']}>
            {`${numGuesses}/6`}
          </div>
          <Button className={styles['leaderboard-score-submit__button']}
            success={scoreSubmitted}
            disabled={scoreSubmitted || !submitEnabled}
            onClick={submitScore}>
            {scoreSubmitted ? 'SUBMITTED' : 'SUBMIT'}
          </Button>
        </div>
      </div>
    </div>
  );
};

Leaderboard.propTypes = {
  displayConfetti: PropTypes.bool
};

export default Leaderboard;
