import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../shared/button/Button';
import MenuLink from './MenuLink';
import styles from './Menu.module.scss';
import { MainContext } from '../../../shared/context/main-context';
import { AuthContext } from '../../../shared/context/auth-context';
import { THEMES } from '../../../shared/constants';

const Menu = () => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const { theme, setTheme } = useContext(MainContext);

  const adminPages = [
    {
      title: 'Feature Requests',
      path: '/feature-requests'
    }
  ];
  const userPages = [
    {
      title: 'Guest Book',
      path: '/guestbook'
    },
    {
      title: 'Steword',
      path: '/steword'
    }
  ];

  const currentRoute = '/' + location.pathname.split('/')[1];

  const toggleTheme = () => {
    let newTheme = THEMES.DEFAULT;
    if (theme === THEMES.DEFAULT) {
      newTheme = THEMES.DARK;
    }

    setTheme(newTheme);
  };

  return (
    <div className={styles.menu}>
      <div className={styles['menu-header']}>Menu</div>
      <div className={styles['menu-body']}>
        <div className={styles['user-pages']}>
          {userPages.map((page, index) => (
            <MenuLink
              key={index}
              path={page.path}
              title={page.title}
              isSelected={page.path === currentRoute}
            />
          ))}
        </div>
        {user && user.isAdmin && (
          <div className={styles['admin-pages']}>
            <div className={styles['admin-pages__title']}>
              ADMIN PAGES
            </div>
            {adminPages.map((page, index) => (
              <MenuLink
                key={index}
                path={page.path}
                title={page.title}
                isSelected={page.path === currentRoute}
              />
            ))}
          </div>
        )}
      </div>
      <div className={styles['menu-footer']}>
        <Button
          className={styles['theme-toggle-button']}
          onClick={toggleTheme}
        >
          {`${theme === THEMES.DARK ? 'LIGHT' : 'DARK'} THEME`}
        </Button>
      </div>
    </div>
  );
};

export default Menu;
