import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { formatResultsForDisplay } from '../utils';
import caret from '../../../res/caret.svg';
import caretDark from '../../../res/caret-dark.svg';
import { THEMES } from '../../../shared/constants';
import { MainContext } from '../../../shared/context/main-context';
import { StewordContext } from '../../../shared/context/steword-context';
import styles from './LeaderboardRow.module.scss';

const LeaderboardRow = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { theme } = useContext(MainContext);
  const { gameMode } = useContext(StewordContext);
  const { index, score, isLoadingLeaderboard } = props;

  let placeText;
  switch (index) {
    case 0:
      placeText = String.fromCodePoint(0x1f947);
      break;
    case 1:
      placeText = String.fromCodePoint(0x1f948);
      break;
    case 2:
      placeText = String.fromCodePoint(0x1f949);
      break;
    default:
      placeText = `${index + 1}.`;
  }

  const toggleRowExpansion = () => {
    if (isLoadingLeaderboard) return;
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  return (
    <div className={styles['leaderboard-row']} onClick={toggleRowExpansion}>
      <div className={styles['place-cell']}>{ placeText }</div>
      <div className={styles['name-cell']}>{ score.name || '---' }</div>
      <div className={styles['score-cell']}>
        { score.numberOfGuesses ? `${score.numberOfGuesses > 6 ? 'X' : score.numberOfGuesses}/6` : '---' }
      </div>
      <div className={styles['expand-cell']}>
        {!isLoadingLeaderboard && !!score.numberOfGuesses && (
          <img
            className={isExpanded ? styles.expanded : styles.collapsed}
            src={theme === THEMES.DARK ? caretDark : caret}
            alt={isExpanded ? 'Collapse' : 'Expand'}
            height="16"
            width="16"
          />
        )}
      </div>
      { isExpanded && (
        <div className={styles['results-display']}>
          { score.guesses ? formatResultsForDisplay({ guesses: JSON.parse(score.guesses), theme, gameMode }) : '(Not saved)'}
        </div>
      )}
    </div>
  );
};

LeaderboardRow.propTypes = {
  index: PropTypes.number,
  score: PropTypes.object,
  isLoadingLeaderboard: PropTypes.bool
};

export default LeaderboardRow;
