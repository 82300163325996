import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState(null);

  const login = useCallback((user, token, expirationDate) => {
    setToken(token);
    setUser(user);

    const newExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);
    setTokenExpirationDate(newExpirationDate);

    localStorage.setItem('userData', JSON.stringify({
      user,
      token,
      expirationDate: newExpirationDate.toISOString()
    }));
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    setTokenExpirationDate(null);

    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData.token && new Date(userData.expirationDate) > new Date()) {
      login(userData.user, userData.token, new Date(userData.expirationDate));
    }
  }, [login]);

  return { token, login, logout, user };
};
