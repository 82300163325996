import React from 'react';
import PropTypes from 'prop-types';
import BoardRow from './BoardRow';
import styles from './Board.module.scss';

const Board = (props) => {
  return (
    <React.Fragment>
      <div className={styles.board}>
        {props.guesses.map((row, index) => (
          <BoardRow key={index} letters={row} />
        ))}
      </div>
    </React.Fragment>
  );
};

Board.propTypes = {
  guesses: PropTypes.array
};

export default Board;
