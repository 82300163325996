import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

const Button = (props) => {
  const buttonClassName = `${styles.button} ${props.className}
    ${props.disabled && styles.disabled}
    ${props.success && styles.success}`;

  let buttonText = props.children;
  const successEmoji = props.successEmoji || String.fromCodePoint(0x2705);
  if (props.success) {
    buttonText = `${successEmoji} ${buttonText}`;
  }

  const buttonOnClick = () => {
    if (props.disabled) {
      return;
    }

    return props.onClick();
  };

  return (
    <button
      className={buttonClassName}
      tabIndex="0"
      onClick={buttonOnClick}>
        {buttonText}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  children: PropTypes.string,
  successEmoji: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
