import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
// import styles from './AnnouncementModal.module.scss';

const AnnouncementModal = (props) => {
  const { announcement } = props;

  const unescapeNewLines = (contentString) => {
    const lines = contentString.split('\\n');
    return lines.map((line, index) => (
      <p key={index}>
        {line}
      </p>
    ));
  };

  const getHeaderContent = () => {
    return (
      <div>
        {announcement.headerContent}
      </div>
    );
  };

  const getBodyContent = () => {
    return (
      <div>
        {unescapeNewLines(announcement.bodyContent)}
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <div>
        {announcement.footerContent}
      </div>
    );
  };

  return (
    <Modal
      headerContent={getHeaderContent()}
      bodyContent={getBodyContent()}
      footerContent={getFooterContent()}
      zIndex={21}
      onClose={props.onClose}
    />
  );
};

AnnouncementModal.propTypes = {
  announcement: PropTypes.object,
  onClose: PropTypes.func
};

export default AnnouncementModal;
