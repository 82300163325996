import React from 'react';
import MainPage from './pages/MainPage';
import { AuthContext } from './shared/context/auth-context';
import { useAuth } from './shared/hooks/auth-hook';

const App = () => {
  const { token, login, logout, user } = useAuth();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        user,
        login,
        logout
      }}
    >
      <MainPage />
    </AuthContext.Provider>
  );
};

export default App;
