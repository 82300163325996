import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toast.module.scss';

const Toast = (props) => {
  const { content, zIndex } = props;

  return (
    <div
      className={styles['toast-body']}
      style={{ zIndex }}
    >
      { content }
    </div>
  );
};

Toast.propTypes = {
  content: PropTypes.element,
  zIndex: PropTypes.number
};

export default Toast;
