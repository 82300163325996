import React, { useState, useContext } from 'react';
import styles from './Login.module.scss';
import Button from '../../shared/button/Button';
import { AuthContext } from '../../../shared/context/auth-context';
import { MainContext } from '../../../shared/context/main-context';
import DisclaimerModal from '../../steword/modals/DisclaimerModal';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(true);
  const [hasTouchedEmail, setHasTouchedEmail] = useState(false);
  const [hasTouchedPassword, setHasTouchedPassword] = useState(false);
  const [hasTouchedConfirmPassword, setHasTouchedConfirmPassword] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [displayDisclaimerModal, setDisplayDisclaimerModal] = useState(false);

  const authContext = useContext(AuthContext);
  const mainContext = useContext(MainContext);
  const loginActionString = 'LOG IN';
  const signUpActionString = 'SIGN UP';
  const isLoggingInString = '...';

  const getPrimaryButtonString = () => {
    if (isLoggingIn) {
      return isLoggingInString;
    }

    return isLoginMode ? loginActionString : signUpActionString;
  };

  const getSecondaryButtonString = () => {
    if (isLoggingIn) {
      return isLoggingInString;
    }

    return 'GO TO ' + (isLoginMode ? signUpActionString : loginActionString);
  };

  const validateEmail = (currentEmail) => {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    setIsEmailValid(!!currentEmail.match(pattern));
  };

  const validatePassword = (currentPassword) => {
    const pattern = /.{8,}/;
    setIsPasswordValid(!!currentPassword.match(pattern));
    setIsConfirmPasswordValid(currentPassword === confirmPassword);
  };

  const validateConfirmPassword = (currentConfirmPassword) => {
    setIsConfirmPasswordValid(currentConfirmPassword === password);
  };

  const emailChangeHandler = ({ target }) => {
    const currentEmail = target.value;
    if (!isLoginMode) {
      setHasTouchedEmail(true);

      validateEmail(currentEmail);
    }

    setEmail(currentEmail);
  };

  const passwordChangeHandler = ({ target }) => {
    const currentPassword = target.value;
    if (!isLoginMode) {
      setHasTouchedPassword(true);

      validatePassword(currentPassword);
    }

    setPassword(currentPassword);
  };

  const confirmPasswordChangeHandler = ({ target }) => {
    const currentConfirmPassword = target.value;
    if (!isLoginMode) {
      setHasTouchedConfirmPassword(true);

      validateConfirmPassword(currentConfirmPassword);
    }

    setConfirmPassword(currentConfirmPassword);
  };

  const toggleLoginMode = () => {
    if (isLoggingIn) {
      return;
    }

    setIsLoginMode(!isLoginMode);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setHasTouchedEmail(false);
    setHasTouchedPassword(false);
    setHasTouchedConfirmPassword(false);
    setIsEmailValid(true);
    setIsPasswordValid(true);
    setIsConfirmPasswordValid(true);
  };

  const attemptLogin = async () => {
    if (isLoggingIn) {
      return;
    }

    setIsLoggingIn(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            password
          })
        }
      );
      const responseData = await response.json();
      if (responseData.message) {
        setIsLoggingIn(false);
        return;
      }
      authContext.login(responseData.user, responseData.token);
      setIsLoggingIn(false);
      window.location.reload(false);
    } catch (err) {
      console.log(err.message);
      setIsLoggingIn(false);
    }
  };

  const attemptSignup = async () => {
    if (isLoggingIn) {
      return;
    } else if (signUpDisabled()) {
      return;
    }

    setIsLoggingIn(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/signup`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email,
            password
          })
        }
      );
      const responseData = await response.json();
      authContext.login(responseData.user, responseData.token);
      setIsLoggingIn(false);
      mainContext.setUserPaneOpen(false);
      window.location.reload(false);
    } catch (err) {
      console.log(err.message);
      setIsLoggingIn(false);
    }
  };

  const hasStartedSignUp = () => {
    return hasTouchedEmail ||
      hasTouchedPassword ||
      hasTouchedConfirmPassword;
  };

  const signUpInvalid = () => {
    return !isEmailValid ||
      !isPasswordValid ||
      !isConfirmPasswordValid;
  };

  const signUpDisabled = () => {
    if (isLoginMode) {
      return false;
    }

    return !hasTouchedEmail ||
      !hasTouchedPassword ||
      !hasTouchedConfirmPassword ||
      signUpInvalid();
  };

  const loginFieldInputClass = (isInputValid) => {
    return isInputValid ? styles['login-input'] : styles['login-input__invalid'];
  };

  const loginFieldLabelClass = (isInputValid) => {
    return isInputValid ? styles['login-label'] : styles['login-label__invalid'];
  };

  const signUpButtonClass = () => {
    return !signUpDisabled() ? styles['login-button__primary'] : styles['login-button__primary-disabled'];
  };

  const openDisclaimerModal = () => {
    setDisplayDisclaimerModal(true);
  };

  const closeDisclaimerModal = () => {
    setDisplayDisclaimerModal(false);
  };

  return (
    <div className={styles.login}>
      {displayDisclaimerModal && <DisclaimerModal onClose={closeDisclaimerModal} />}
      <div className={styles['login-header']}>
        {isLoginMode ? loginActionString : signUpActionString}
        {hasStartedSignUp() && signUpInvalid() &&
          <div className={styles['login-header__error']}>
            {!isEmailValid && <p className={styles['login-header__error-message']}>Invalid email.</p>}
            {!isPasswordValid && <p className={styles['login-header__error-message']}>Passwords must be at least 8 characters.</p>}
            {!isConfirmPasswordValid && <p className={styles['login-header__error-message']}>Passwords do not match.</p>}
          </div>
        }
      </div>
      <div className={styles['login-body']}>
        <div>
          <p className={loginFieldLabelClass(isEmailValid)}>
            Email
          </p>
          <input
            className={loginFieldInputClass(isEmailValid)}
            type='text'
            value={email}
            onChange={emailChangeHandler}
          />
        </div>
        <div>
          <p className={loginFieldLabelClass(isPasswordValid)}>
            Password
          </p>
          <input
            className={loginFieldInputClass(isPasswordValid)}
            type='password'
            value={password}
            onChange={passwordChangeHandler}
          />
        </div>
        { !isLoginMode
          ? <div>
          <p className={loginFieldLabelClass(isConfirmPasswordValid)}>
            Confirm Password
          </p>
          <input
            className={loginFieldInputClass(isConfirmPasswordValid)}
            type="password"
            value={confirmPassword}
            onChange={confirmPasswordChangeHandler}
          />
        </div>
          : null}
        <Button
          className={signUpButtonClass()}
          disabled={signUpDisabled()}
          onClick={isLoginMode ? attemptLogin : attemptSignup}>
          { getPrimaryButtonString() }
        </Button>
      </div>
      <div className={styles['login-footer']}>
          <div>
            <p className={styles['login-label']}>
              {isLoginMode ? 'Don\'t have an account?' : 'Already have an account?' }
            </p>
          </div>
          <Button className={styles['login-button__secondary']} onClick={toggleLoginMode}>
            { getSecondaryButtonString() }
          </Button>
        </div>
        <button
          className={styles['login-footer__link']}
          onClick={openDisclaimerModal}>
          Why create an account?
        </button>
    </div>
  );
};

export default Login;
