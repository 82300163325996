import React from 'react';
import PropTypes from 'prop-types';
import styles from './PlayerStats.module.scss';
import { GAME_MODES } from './utils';

const PlayerStats = (props) => {
  const { celebrate, gameMode, playerMetadata } = props;
  const { currentStreak, maxStreak, winningGuess } = playerMetadata;

  const newCurrentStreak = currentStreak > 0;
  const newMaxStreak = (maxStreak === currentStreak) && maxStreak !== 0;

  const totalPlays = Object.values(winningGuess).reduce((total, guess) => total + guess, 0);

  const winningGuessByPercentage = {};
  Object.keys(winningGuess).forEach(key => {
    winningGuessByPercentage[key] = (winningGuess[key] / totalPlays) * 100;
  });

  return (
    <div className={styles['player-stats']}>
      <div className={styles['title']}>
        YOUR STATS
      </div>
      <div className={styles['stats']}>
        <div className={styles['streak-stats']}>
          <div className={styles['current-streak']}>
            <div className={styles['label']}>
              CURRENT STREAK
            </div>
            <div className={styles['value']}>
              <div className={`${styles['new-streak']} ${newCurrentStreak && celebrate && styles['scroll']} ${gameMode === GAME_MODES.hard ? styles['hard-mode'] : ''}`}>
                {currentStreak}
              </div>
              <div className={`${styles['old-streak']} ${newCurrentStreak && celebrate && styles['scroll']}`}>
                {newCurrentStreak && celebrate ? currentStreak - 1 : currentStreak}
              </div>
            </div>
          </div>
          <div className={styles['highest-streak']}>
            <div className={styles['label']}>
              LONGEST STREAK
            </div>
            <div className={styles['value']}>
              <div className={`${styles['new-streak']} ${newMaxStreak && celebrate && styles['scroll']} ${gameMode === GAME_MODES.hard ? styles['hard-mode'] : ''}`}>
                {maxStreak}
              </div>
              <div className={`${styles['old-streak']} ${newMaxStreak && celebrate && styles['scroll']}`}>
                {newMaxStreak && celebrate ? maxStreak - 1 : maxStreak}
              </div>
            </div>
          </div>
        </div>
        <div className={styles['guess-stats']}>
          <div className={styles['label']}>
            SCORES
          </div>
          <div className={styles['stats-grid']}>
            {Object.keys(winningGuess).map(guess => {
              return (
                <div className={styles['guess-row']} key={guess}>
                  <div className={styles['guess-label']}>
                    {guess}
                  </div>
                  <div className={styles['guess-graph']}>
                    <div className={styles['guess-bar']} style={{ width: `${winningGuessByPercentage[guess]}%` }} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

PlayerStats.propTypes = {
  celebrate: PropTypes.bool,
  gameMode: PropTypes.string,
  playerMetadata: PropTypes.object
};

export default PlayerStats;
