import { useState } from 'react';

export const useSteword = (props) => {
  const [gameMode, setGameMode] = useState(props.gameMode);
  const [resultsDisplayed, setResultsDisplayed] = useState(false);
  const [socket, setSocket] = useState({});

  return {
    gameMode,
    resultsDisplayed,
    socket,
    setGameMode,
    setResultsDisplayed,
    setSocket
  };
};
