import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StewordContext } from '../../../shared/context/steword-context';
import Key, { KEY_STATE } from '../../../classes/steword/Key';
import styles from './KeyboardKey.module.scss';
import { GAME_MODES } from '../utils';

const KeyboardKey = (props) => {
  const { gameMode } = useContext(StewordContext);
  const { key, state } = props.keyboardKey;

  let cssClass;
  let isFlipped = false;
  switch (state) {
    case KEY_STATE.INCORRECT:
      isFlipped = true;
      cssClass = styles.incorrect;
      break;
    case KEY_STATE.CORRECT_LETTER:
      isFlipped = true;
      cssClass = styles['correct-letter'];
      break;
    case KEY_STATE.CORRECT:
      isFlipped = true;
      cssClass = styles.correct;
      break;
    case KEY_STATE.NOT_GUESSED:
    default:
      break;
  }

  const displayKey = (key) => {
    if (key === 'Backspace') {
      return 'Del';
    } else {
      return key;
    }
  };

  const longKey = !!(key === 'Enter' || key === 'Backspace');

  const onLetterClick = () => {
    if (key === 'Enter') {
      props.performGuess();
    } else if (key === 'Backspace') {
      props.performBackspace();
    } else {
      props.addLetterToGuess(key);
    }
  };

  return (
    <div className={`${styles['keyboard-key-parent']} ${longKey && styles.long}`}>
      <div className={`${styles.front} ${isFlipped ? styles.flipped : ''}`}>
        <button
          tabIndex={isFlipped ? '-1' : '0'}
          onClick={onLetterClick}
          className={styles['keyboard-key']}
        >
          {displayKey(key)}
        </button>
      </div>
      <div className={`${styles.back} ${isFlipped ? styles.flipped : ''}`}>
        <button
          tabIndex={isFlipped ? '0' : '-1'}
          onClick={onLetterClick}
          className={`${styles['keyboard-key']} ${cssClass} ${gameMode === GAME_MODES.hard ? styles['hard-mode'] : ''}`}
        >
          {displayKey(key)}
        </button>
      </div>
    </div>
  );
};

KeyboardKey.propTypes = {
  keyboardKey: PropTypes.instanceOf(Key),
  row: PropTypes.string,
  addLetterToGuess: PropTypes.func,
  performGuess: PropTypes.func,
  performBackspace: PropTypes.func
};

export default KeyboardKey;
