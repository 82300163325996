import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { StewordContext } from '../../../shared/context/steword-context';
import Letter, { LETTER_STATE } from '../../../classes/steword/Letter';
import styles from './BoardCell.module.scss';
import { GAME_MODES } from '../utils';

const BoardCell = (props) => {
  const { gameMode } = useContext(StewordContext);
  const { correctLetter, letter, state } = props.letter;

  let cssClass;
  let isFlipped = false;
  let isEmpty = false;
  let isInvalid = false;
  switch (state) {
    case LETTER_STATE.INCORRECT:
      isFlipped = true;
      cssClass = styles.incorrect;
      break;
    case LETTER_STATE.INCORRECT_FINAL:
      isFlipped = true;
      cssClass = styles['incorrect-final'];
      break;
    case LETTER_STATE.CORRECT_LETTER:
      isFlipped = true;
      cssClass = styles['correct-letter'];
      break;
    case LETTER_STATE.CORRECT:
      isFlipped = true;
      cssClass = styles.correct;
      break;
    case LETTER_STATE.INVALID:
      isInvalid = true;
      break;
    case LETTER_STATE.EMPTY:
      isEmpty = true;
      break;
    default:
      break;
  }

  return (
    <div className={styles['board-cell']}>
      <div
        className={`${styles.front} 
        ${isEmpty ? styles.empty : styles['non-empty']} 
        ${isInvalid ? styles.invalid : ''} 
        ${isFlipped ? styles.flipped : ''}`}
      >
        {letter}
      </div>
      <div
        className={`${styles.back} 
        ${cssClass} 
        ${isFlipped ? styles.flipped : ''} 
        ${gameMode === GAME_MODES.hard ? styles['hard-mode'] : ''}`}
      >
        { correctLetter || letter}
      </div>
    </div>
  );
};

BoardCell.propTypes = {
  letter: PropTypes.instanceOf(Letter).isRequired
};

export default BoardCell;
