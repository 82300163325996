import React, { useContext } from 'react';
import styles from './Header.module.scss';
import menu from '../../res/menu.svg';
import menuDark from '../../res/menu-dark.svg';
import loggedOut from '../../res/loggedOut.svg';
import loggedOutDark from '../../res/loggedOut-dark.svg';
import loggedIn from '../../res/loggedIn.svg';
import loggedInDark from '../../res/loggedIn-dark.svg';
import backLeft from '../../res/backLeft.svg';
import backLeftDark from '../../res/backLeft-dark.svg';
import backRight from '../../res/backRight.svg';
import backRightDark from '../../res/backRight-dark.svg';
import { AuthContext } from '../../shared/context/auth-context';
import { MainContext } from '../../shared/context/main-context';
import { THEMES } from '../../shared/constants';

const Header = () => {
  const authContext = useContext(AuthContext);
  const {
    theme,
    menuPaneOpen,
    userPaneOpen,
    setMenuPaneOpen,
    setUserPaneOpen
  } = useContext(MainContext);

  const openMenu = () => {
    setMenuPaneOpen(!menuPaneOpen);
    setUserPaneOpen(false);
  };

  const openLogin = () => {
    setUserPaneOpen(!userPaneOpen);
    setMenuPaneOpen(false);
  };

  // TODO - icon theming is messy
  const getThemedLoggedInIcon = () => {
    switch (theme) {
      case THEMES.DARK:
        return loggedInDark;
      case THEMES.DEFAULT:
      default:
        return loggedIn;
    }
  };

  const getThemedLoggedOutIcon = () => {
    switch (theme) {
      case THEMES.DARK:
        return loggedOutDark;
      case THEMES.DEFAULT:
      default:
        return loggedOut;
    }
  };

  const getThemedUserOptionsIcon = () => {
    return authContext.isLoggedIn ? getThemedLoggedInIcon() : getThemedLoggedOutIcon();
  };

  const getThemedMenuIcon = () => {
    switch (theme) {
      case THEMES.DARK:
        return menuDark;
      case THEMES.DEFAULT:
      default:
        return menu;
    }
  };

  const getThemedBackLeftIcon = () => {
    switch (theme) {
      case THEMES.DARK:
        return backLeftDark;
      case THEMES.DEFAULT:
      default:
        return backLeft;
    }
  };

  const getThemedBackRightIcon = () => {
    switch (theme) {
      case THEMES.DARK:
        return backRightDark;
      case THEMES.DEFAULT:
      default:
        return backRight;
    }
  };

  return (
    <div className={styles.header}>
      <button className={styles.icon1} onClick={openMenu}>
        <img src={menuPaneOpen ? getThemedBackLeftIcon() : getThemedMenuIcon()} alt="Menu" height="50" width="50"/>
      </button>
      <div className={styles.title}>bstewart.io</div>
      <button className={styles.icon2} onClick={openLogin}>
        <img src={userPaneOpen ? getThemedBackRightIcon() : getThemedUserOptionsIcon()} alt="Login" height="50" width="50"/>
      </button>
    </div>
  );
};

export default Header;
