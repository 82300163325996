import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { MainContext } from '../../../shared/context/main-context';
import { getThemeClass } from '../../../shared/utils';
import closeButton from '../../../res/closeButton.svg';
import closeButtonDark from '../../../res/closeButton-dark.svg';
import modalStyles from './Modal.module.scss';
import themeStyles from '../../../styles/themes.module.scss';
import { THEMES } from '../../../shared/constants';

const Backdrop = (props) => {
  const { onClose, zIndex } = props;
  const { theme } = useContext(MainContext);

  return (
    <div
      className={`${modalStyles.backdrop} ${themeStyles[getThemeClass(theme)]}`}
      style={{ zIndex }}
      onClick={onClose}
    />
  );
};

const ModalOverlay = (props) => {
  const {
    bodyContent,
    className,
    footerContent,
    headerContent,
    onClose,
    zIndex
  } = props;
  const { theme } = useContext(MainContext);

  return (
    <div
      className={`${modalStyles.modal} ${themeStyles[getThemeClass(theme)]} ${className}`}
      style={{ zIndex }}
    >
      {onClose && (
        <button className={modalStyles['modal-close-button']} onClick={onClose}>
          <img
            className={modalStyles.icon}
            src={theme === THEMES.DARK ? closeButtonDark : closeButton}
            alt="Close"
            height="30px"
            width="30px"
          />
        </button>
      )}
      <div className={modalStyles['modal-header']}>{headerContent}</div>
      <div className={modalStyles['modal-body']}>{bodyContent}</div>
      <div className={modalStyles['modal-footer']}>{footerContent}</div>
    </div>
  );
};

const Modal = (props) => {
  const {
    bodyContent,
    className,
    footerContent,
    headerContent,
    onClose,
    zIndex
  } = props;

  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop
          zIndex={zIndex ? zIndex - 2 : 398}
          onClose={onClose}
        />,
        document.getElementById('backdrop-root')
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          className={className}
          zIndex={zIndex || 400}
          headerContent={headerContent}
          bodyContent={bodyContent}
          footerContent={footerContent}
          onClose={onClose}
        />,
        document.getElementById('overlay-root')
      )}
    </React.Fragment>
  );
};

export default Modal;

Modal.propTypes = {
  zIndex: PropTypes.number,
  headerContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string
  ]),
  bodyContent: PropTypes.element,
  className: PropTypes.string,
  footerContent: PropTypes.element,
  onClose: PropTypes.func
};

ModalOverlay.propTypes = {
  zIndex: PropTypes.number,
  headerContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.string
  ]),
  bodyContent: PropTypes.element,
  className: PropTypes.string,
  footerContent: PropTypes.element,
  onClose: PropTypes.func
};

Backdrop.propTypes = {
  zIndex: PropTypes.number,
  onClose: PropTypes.func
};
