// https://github.com/mathusummut/confetti.js

import { GAME_MODES } from '../../components/steword/utils';

const supportsAnimationFrame =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame;

const colorOptions = {
  red: '255,0,0',
  gold: '255,215,0',
  silver: '192,192,192',
  yellowgreen: '154,205,50',
  gray: '50,50,50'
};

export default class Confetti {
  maxCount = null; // set max confetti count
  speed = null; // set the particle animation speed
  frameInterval = 15; // the confetti animation frame interval in milliseconds
  alpha = 1.0; // the alpha opacity of the confetti (between 0 and 1, where 1 is opaque and 0 is invisible)
  streamingConfetti = false;
  pause = false;
  lastFrameTime = Date.now();
  particles = [];
  waveAngle = 0;
  context = null;
  colors = [`rgba(${colorOptions.yellowgreen},`, `rgba(${colorOptions.gold},`, `rgba(${colorOptions.gray},`];
  timeout = null;

  resetParticle (particle, width, height) {
    particle.color = `${this.colors[(Math.random() * this.colors.length) | 0]}${this.alpha})`;
    particle.color2 = `${this.colors[(Math.random() * this.colors.length) | 0]}${this.alpha})`;
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = Math.random() * Math.PI;
    return particle;
  }

  toggleConfettiPause () {
    if (this.pause) this.resumeConfetti();
    else this.pauseConfetti();
  }

  isConfettiPaused () {
    return this.pause;
  }

  pauseConfetti () {
    this.pause = true;
  }

  resumeConfetti () {
    this.pause = false;
    this.runAnimation();
  }

  runAnimation () {
    if (this.pause) return;

    if (this.particles.length === 0) {
      this.context.clearRect(0, 0, window.innerWidth, window.innerHeight);
      this.animationTimer = null;
    } else {
      const now = Date.now();
      const delta = now - this.lastFrameTime;

      if (!supportsAnimationFrame || delta > this.frameInterval) {
        this.context.clearRect(0, 0, window.innerWidth, window.innerHeight);
        this.updateParticles();
        this.drawParticles();
        this.lastFrameTime = now - (delta % this.frameInterval);
      }

      this.animationTimer = requestAnimationFrame(() => this.runAnimation());
    }
  }

  startConfetti (guesses, gameMode) {
    const primaryColor = gameMode === GAME_MODES.hard ? colorOptions.red : colorOptions.gold;
    this.colors[0] = `rgba(${primaryColor},`;

    switch (guesses) {
      case '1':
        this.maxCount = 1500;
        this.speed = 10;
        this.colors = [`rgba(${primaryColor},`, `rgba(${primaryColor},`, `rgba(${primaryColor},`];
        break;
      case '2':
        this.maxCount = 300;
        this.speed = 3;
        this.colors = [`rgba(${colorOptions.silver},`, `rgba(${colorOptions.silver},`, `rgba(${colorOptions.silver},`];
        break;
      case '3':
        this.maxCount = 200;
        this.speed = 2;
        break;
      case '4':
        this.maxCount = 100;
        this.speed = 2;
        break;
      case '5':
        this.maxCount = 50;
        this.speed = 2;
        break;
      case '6':
      default:
        this.maxCount = 25;
        this.speed = 1;
        break;
    }

    const width = window.innerWidth;
    const height = window.innerHeight;

    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (callback) {
          return window.setTimeout(callback, this.frameInterval);
        }
      );
    })();

    let canvas = document.getElementById('confetti-canvas');
    if (canvas === null) {
      canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'confetti-canvas');
      canvas.setAttribute('style', 'display:block;z-index:18;pointer-events:none;position:fixed;top:0');
      document.body.prepend(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener(
        'resize',
        function () {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        },
        true
      );
      this.context = canvas.getContext('2d');
    } else if (this.context === null) this.context = canvas.getContext('2d');

    const count = this.maxCount;
    while (this.particles.length < count) this.particles.push(this.resetParticle({}, width, height));

    this.streamingConfetti = true;
    this.pause = false;
    this.runAnimation();

    if (this.timeout) {
      window.setTimeout(() => this.stopConfetti(), this.timeout);
    }
  }

  stopConfetti () {
    this.streamingConfetti = false;
  }

  removeConfetti () {
    this.stopConfetti();
    this.pause = false;
    this.particles = [];
  }

  toggleConfetti () {
    if (this.streamingConfetti) this.stopConfetti();
    else this.startConfetti();
  }

  isConfettiRunning () {
    return this.streamingConfetti;
  }

  drawParticles () {
    let particle;
    let x;
    let x2;
    let y2;
    for (let i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      this.context.beginPath();
      this.context.lineWidth = particle.diameter;
      x2 = particle.x + particle.tilt;
      x = x2 + particle.diameter / 2;
      y2 = particle.y + particle.tilt + particle.diameter / 2;
      if (this.gradient) {
        const gradient = this.context.createLinearGradient(x, particle.y, x2, y2);
        gradient.addColorStop('0', particle.color);
        gradient.addColorStop('1.0', particle.color2);
        this.context.strokeStyle = gradient;
      } else this.context.strokeStyle = particle.color;
      this.context.moveTo(x, particle.y);
      this.context.lineTo(x2, y2);
      this.context.stroke();
    }
  }

  updateParticles () {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let particle;
    this.waveAngle += 0.01;
    for (let i = 0; i < this.particles.length; i++) {
      particle = this.particles[i];
      if (!this.streamingConfetti && particle.y < -15) particle.y = height + 100;
      else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(this.waveAngle) - 0.5;
        particle.y += (Math.cos(this.waveAngle) + particle.diameter + this.speed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (this.streamingConfetti && this.particles.length <= this.maxCount) this.resetParticle(particle, width, height);
        else {
          this.particles.splice(i, 1);
          i--;
        }
      }
    }
  }
}
