import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { THEMES } from '../constants';

export const useMain = () => {
  const [menuPaneOpen, setMenuPaneOpen] = useState(false);
  const [userPaneOpen, setUserPaneOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || THEMES.DEFAULT);
  const [toasts, setToasts] = useState([]);

  const defaultToastDuration = 2000;

  const pushToast = (toastToPush) => {
    const toast = {
      ...toastToPush,
      id: uuid(),
      duration: toastToPush.duration || defaultToastDuration,
      active: false
    };
    setToasts(prevToasts => {
      return [...prevToasts, toast];
    });
  };

  const popToast = (toastToPop) => {
    setToasts(prevToasts => {
      const indexToPop = prevToasts.findIndex(toast => toast.id === toastToPop.id);

      if (indexToPop >= 0) {
        return prevToasts.toSpliced(indexToPop, 1);
      }
    });
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);

    const faviconElement = document.getElementById('favicon');
    faviconElement.href = `${process.env.PUBLIC_URL}/bstewartio-${theme === THEMES.DARK ? 'dark-' : ''}32.png`;
  }, [theme]);

  return {
    menuPaneOpen,
    userPaneOpen,
    theme,
    toasts,
    popToast,
    pushToast,
    setMenuPaneOpen,
    setUserPaneOpen,
    setTheme
  };
};
