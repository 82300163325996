import React from 'react';
import PropTypes from 'prop-types';

import BoardCell from './BoardCell';
import Letter from '../../../classes/steword/Letter';
import styles from './BoardRow.module.css';

const BoardRow = (props) => {
  return (
    <div className={styles['board-row']}>
      {props.letters.map((letter, index) => (
        <BoardCell key={index} letter={letter} />
      ))}
    </div>
  );
};

BoardRow.propTypes = {
  letters: PropTypes.arrayOf(PropTypes.instanceOf(Letter))
};

export default BoardRow;
