import React from 'react';
import PropTypes from 'prop-types';
import KeyboardRow, { KEYBOARD_ROWS } from './KeyboardRow';
import styles from './Keyboard.module.css';

const Keyboard = (props) => {
  const keyboardRows = [
    { rowPosition: KEYBOARD_ROWS.TOP },
    { rowPosition: KEYBOARD_ROWS.MIDDLE },
    { rowPosition: KEYBOARD_ROWS.BOTTOM }
  ];

  return (
    <div className={styles.keyboard}>
      {keyboardRows.map((row, index) => (
        <KeyboardRow
          key={index}
          addLetterToGuess={props.addLetterToGuess}
          performGuess={props.performGuess}
          performBackspace={props.performBackspace}
          guessedLetters={props.guessedLetters}
          rowPosition={row.rowPosition}
        />
      ))}
    </div>
  );
};

Keyboard.propTypes = {
  addLetterToGuess: PropTypes.func,
  performGuess: PropTypes.func,
  performBackspace: PropTypes.func,
  guessedLetters: PropTypes.object
};

export default Keyboard;
