import React, { useContext, useEffect, useState } from 'react';
import Button from '../../shared/button/Button';
import styles from './UserOptions.module.scss';
import { AuthContext } from '../../../shared/context/auth-context';

const UserOptions = () => {
  const authContext = useContext(AuthContext);
  const [userEmail, setUserEmail] = useState(null);
  const [userFirstName, setUserFirstName] = useState(null);
  const [userLastName, setUserLastName] = useState(null);
  const [prevUserEmail, setPrevUserEmail] = useState(null);
  const [prevUserFirstName, setPrevUserFirstName] = useState(null);
  const [prevUserLastName, setPrevUserLastName] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);

  const logout = () => {
    authContext.logout();
    window.location.reload(false);
  };

  const userEmailChangeHandler = ({ target }) => {
    setUserEmail(target.value);
  };

  const userFirstNameChangeHandler = ({ target }) => {
    setUserFirstName(target.value);
  };

  const userLastNameChangeHandler = ({ target }) => {
    setUserLastName(target.value);
  };

  const cancelEditMode = () => {
    if (isSavingSettings) return;

    setUserEmail(prevUserEmail);
    setUserFirstName(prevUserFirstName);
    setUserLastName(prevUserLastName);
    setIsEditMode(false);
  };

  const saveUserSettings = async () => {
    if (isSavingSettings) return;

    setIsSavingSettings(true);
    try {
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user-settings/user/${authContext.user.id}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${authContext.token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: userEmail,
              firstName: userFirstName,
              lastName: userLastName
            })
          }
      );
      const responseData = await response.json();
      setUserEmail(responseData.email);
      setUserFirstName(responseData.firstName);
      setUserLastName(responseData.lastName);
      setPrevUserEmail(responseData.email);
      setPrevUserFirstName(responseData.firstName);
      setPrevUserLastName(responseData.lastName);
      setIsSavingSettings(false);
      setIsEditMode(false);
    } catch (err) {
      console.log(err.message);
      setIsSavingSettings(false);
      setIsEditMode(false);
    }
  };

  useEffect(() => {
    async function fetchUserData () {
      const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user-settings/user/${authContext.user.id}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authContext.token}`
            }
          }
      );
      const responseData = await response.json();

      if (responseData.errorMessage) {
        // TODO - display error message?
        return;
      }

      setUserEmail(responseData.email);
      setUserFirstName(responseData.firstName);
      setUserLastName(responseData.lastName);

      setPrevUserEmail(responseData.email);
      setPrevUserFirstName(responseData.firstName);
      setPrevUserLastName(responseData.lastName);
    }

    try {
      if (!authContext.user) return;

      fetchUserData();
    } catch (err) {
      console.log(err);
    }
  }, [authContext.user, authContext.token]);

  return (
    <div className={styles['user-options']}>
      <div className={styles['user-options__header']}>
        USER SETTINGS
      </div>
      <div className={styles['user-options__body']}>
        { !isEditMode &&
          <div>
            <p className={styles['user-options__label']}>Email: {userEmail || '?'}</p>
            <p className={styles['user-options__label']}>First Name: {userFirstName || '?'}</p>
            <p className={styles['user-options__label']}>Last Name: {userLastName || '?'}</p>
          </div> }
        { !isEditMode &&
          <Button className={styles['user-options__button']} onClick={() => setIsEditMode(true)}>
            EDIT
          </Button>}
        { isEditMode &&
          <div>
            <p className={styles['user-options__label']}>Email:</p>
            <input
              className={styles['user-options__input']}
              type='text'
              value={userEmail}
              onChange={userEmailChangeHandler}
            />
            <p className={styles['user-options__label']}>First Name:</p>
            <input
              className={styles['user-options__input']}
              type='text'
              value={userFirstName}
              onChange={userFirstNameChangeHandler}
            />
            <p className={styles['user-options__label']}>Last Name:</p>
            <input
              className={styles['user-options__input']}
              type='text'
              value={userLastName}
              onChange={userLastNameChangeHandler}
            />
          </div> }
        { isEditMode && <div className={styles['user-options__buttons']}>
          <Button className={styles['user-options__button']} onClick={saveUserSettings}>
            { isSavingSettings ? '...' : 'SAVE' }
          </Button>
          <Button className={styles['user-options__button']} onClick={cancelEditMode}>
            { isSavingSettings ? '...' : 'CANCEL' }
          </Button>
        </div>}
        <Button className={styles['user-options__button']} onClick={logout}>
          LOG OUT
        </Button>
      </div>
    </div>
  );
};

export default UserOptions;
