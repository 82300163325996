import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import styles from './HardModeModal.module.scss';
import { HARD_EMOJI } from '../utils';
import Button from '../../shared/button/Button';

const HardModeModal = (props) => {
  const getHeaderText = () => {
    return `${HARD_EMOJI} HARD MODE ${HARD_EMOJI}`;
  };

  const getBodyText = () => {
    return (
      <div className={styles['announcement-modal__body']}>
        <p>
          {'Welcome to '}
          <span className={`${styles['text-strong']} ${styles['text-red']}`}>
            HARD MODE
          </span>!
        </p>
        <p className={styles['text-strong']}>What is HARD MODE?</p>
        <p>
          Unlike regular <span className={styles['text-strong']}>STEWORD</span>, which pulls the daily answer from a bank of around 200 commonly-used,
          five-letter words, <span className={styles['text-strong']}>HARD MODE</span> pulls a random word from the list of the almost 13,000 Scrabble-eligible,
          five-letter words. Meaning, there can be some real weird ones.
        </p>
        <p>
          Oh, and for the sake of aesthetic difference, the letters you guess correctly will now appear <span className={styles['text-red-highlight']}>RED</span>,
          instead of <span className={styles['text-green-highlight']}>GREEN</span> in the regular mode.
        </p>
        <p>
          You&apos;ve been warned. Ready to play?
        </p>
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <Button onClick={props.onClose}>
        {`${HARD_EMOJI} BRING IT ON! ${HARD_EMOJI}`}
      </Button>
    );
  };

  return (
    <Modal
      headerContent={getHeaderText()}
      bodyContent={getBodyText()}
      footerContent={getFooterContent()}
      zIndex={19}
      onClose={props.onClose}
    />
  );
};

HardModeModal.propTypes = {
  onClose: PropTypes.func
};

export default HardModeModal;
