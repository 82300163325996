import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import styles from './DisclaimerModal.module.scss';
import Button from '../../shared/button/Button';

const DisclaimerModal = (props) => {
  const getBodyText = () => {
    return (
      <div className={styles['disclaimer-modal__body']}>
        <p>Accounts don&apos;t do anything - at least not yet.</p>
        <p>I&apos;m just experimenting with creating a site with a functional account system.</p>
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <Button className={styles['disclaimer-modal__button']} onClick={props.onClose}>
        COOL!
      </Button>
    );
  };

  return (
    <Modal
      headerContent="LITERALLY NO REASON"
      bodyContent={getBodyText()}
      footerContent={getFooterContent()}
      onClose={props.onClose}
    />
  );
};

DisclaimerModal.propTypes = {
  onClose: PropTypes.func
};

export default DisclaimerModal;
