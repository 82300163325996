import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../shared/modal/Modal';
import Button from '../../shared/button/Button';
import styles from './FeatureRequestModal.module.scss';

const submissionResponses = [
  'Thanks! I\'ll take a look.',
  'Awesome! I\'ll put it on the roadmap.',
  '... Is that really your best idea? Ok...',
  'Great, more work for me...',
  'Thanks! Expect a response in 2-3 years.',
  'Cool, I\'ll get to it when I get to it'
];

const FeatureRequestModal = (props) => {
  const [userName, setUserName] = useState('');
  const [featureRequestSubmitted, setFeatureRequestSubmitted] = useState(false);
  const [featureRequestText, setFeatureRequestText] = useState('');
  const [submissionResponse, setSubmissionResponse] = useState('');
  const [submitInProgress, setSubmitInProgress] = useState(false);

  useEffect(() => {
    setSubmissionResponse(submissionResponses[Math.floor(Math.random() * submissionResponses.length)]);
  }, []);

  const userNameChangeHandler = ({ target }) => {
    setUserName(target.value);
  };

  const featureRequestTextChangeHandler = ({ target }) => {
    setFeatureRequestText(target.value);
  };

  const featureRequestSubmitDisabled = () => {
    return !userName.length ||
      !featureRequestText.length ||
      submitInProgress ||
      featureRequestSubmitted;
  };

  const inputDisabled = () => {
    return submitInProgress ||
    featureRequestSubmitted;
  };

  const submitFeatureRequest = async () => {
    if (featureRequestSubmitDisabled()) return;

    setSubmitInProgress(true);
    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/steword/submitFeatureRequest`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userName, featureRequestText })
        }
      );
      setSubmitInProgress(false);
      setFeatureRequestSubmitted(true);
      setTimeout(() => {
        props.onClose();
      }, 2000);
    } catch (err) {
      setSubmitInProgress(false);
      console.log(err);
    }
  };

  const getBodyContent = () => {
    return (
      <div className={styles['feature-request-modal__form']}>
        <div className={styles['feature-request-modal__form-item']}>
          <div className={styles['feature-request-modal__label']}>
            Name:
          </div>
          <input
            className={styles['feature-request-modal__input']}
            disabled={inputDisabled()}
            type='text'
            value={userName}
            onChange={userNameChangeHandler}
          />
        </div>
        <div className={styles['feature-request-modal__text']}>
          What features would you like to see in Steword?
        </div>
        <textarea
          className={styles['feature-request-modal__textarea']}
          disabled={inputDisabled()}
          maxLength={3000}
          value={featureRequestText}
          onChange={featureRequestTextChangeHandler}
        />
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <div className={styles['feature-request-modal-footer']}>
        <div className={styles['feature-request-modal-footer__buttons']}>
          <Button
            className={styles['feature-request-modal__button']}
            success={featureRequestSubmitted}
            disabled={featureRequestSubmitDisabled()}
            onClick={submitFeatureRequest}
          >
            SUBMIT
          </Button>
          <Button className={styles['feature-request-modal__button']} onClick={props.onClose}>
            CLOSE
          </Button>
        </div>
        { featureRequestSubmitted && (
          <div className={styles['feature-request-modal__text']}>
            {submissionResponse}
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      zIndex={21}
      headerContent="REQUEST A FEATURE"
      bodyContent={getBodyContent()}
      footerContent={getFooterContent()}
      onClose={props.onClose}
    />
  );
};

FeatureRequestModal.propTypes = {
  onClose: PropTypes.func
};

export default FeatureRequestModal;
