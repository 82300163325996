import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Leaderboard from '../leaderboard/Leaderboard';
import PlayerStats from '../PlayerStats';
import FeatureRequestModal from './FeatureRequestModal';
import Modal from '../../shared/modal/Modal';
import Button from '../../shared/button/Button';
import { GAME_MODES, HARD_EMOJI, formatResultsForDisplay, getSavedNumberOfGuesses } from '../utils';
import { MainContext } from '../../../shared/context/main-context';
import { StewordContext } from '../../../shared/context/steword-context';
import styles from './EndModal.module.scss';

const EndModal = (props) => {
  const { guesses, win, onClose, playerMetadata } = props;

  const [resultsCopied, setResultsCopied] = useState(false);
  const [featureRequestModalVisible, setFeatureRequestModalVisible] = useState(false);
  const { theme } = useContext(MainContext);
  const { gameMode, resultsDisplayed } = useContext(StewordContext);

  const copyResults = () => {
    const gameTitle = gameMode === GAME_MODES.hard ? `${HARD_EMOJI}Steword${HARD_EMOJI} ` : 'Steword ';
    const resultsStr = gameTitle +
      getSavedNumberOfGuesses(gameMode) + '/6\n' +
      formatResultsForDisplay({ guesses, theme, gameMode }) +
      `bstewart.io/steword${gameMode === GAME_MODES.hard ? '/hard' : ''}\n`;
    navigator.clipboard.writeText(resultsStr);
    setResultsCopied(true);
  };

  const getHeaderContent = () => {
    let headerMessage = win ? 'YOU WON!' : 'GAME OVER';
    const className = `${!resultsDisplayed && win && styles['header-message']} ${gameMode === GAME_MODES.hard && styles['hard-mode']}`;
    if (gameMode === GAME_MODES.hard) {
      headerMessage = `${HARD_EMOJI} ${headerMessage} ${HARD_EMOJI}`;
    }

    return (
      <div className={className}>
        {headerMessage}
      </div>
    );
  };

  const getBodyContent = () => {
    return (
      <div className={styles['end-modal-body']}>
        <Leaderboard
          displayConfetti={!resultsDisplayed}
        />
        <PlayerStats
          celebrate={!resultsDisplayed}
          playerMetadata={playerMetadata}
          gameMode={gameMode}
        />
      </div>
    );
  };

  const getFooterContent = () => {
    return (
      <div className={styles['end-modal-footer']}>
        <div className={styles['end-modal-footer__buttons']}>
          <Button
            className={styles['end-modal-button']}
            success={resultsCopied}
            successEmoji={gameMode === GAME_MODES.hard ? HARD_EMOJI : null}
            onClick={copyResults}
          >
            {resultsCopied ? 'COPIED' : 'COPY RESULTS'}
          </Button>
          <Button className={styles['end-modal-button']} onClick={onClose}>
            CLOSE
          </Button>
        </div>
        <button
          className={styles['end-modal-footer__link']}
          onClick={() => setFeatureRequestModalVisible(true)}>
          Request a feature or report a bug
        </button>
        {featureRequestModalVisible && (
          <FeatureRequestModal
            onClose={() => setFeatureRequestModalVisible(false)}
          />)}
      </div>
    );
  };

  return (
    <Modal
      className={`${!resultsDisplayed && win && styles['end-modal-parent']} ${gameMode === GAME_MODES.hard && styles['hard-mode']}`}
      zIndex={19}
      headerContent={getHeaderContent()}
      bodyContent={getBodyContent()}
      footerContent={getFooterContent()}
      onClose={onClose}
    />
  );
};

EndModal.propTypes = {
  guesses: PropTypes.array,
  win: PropTypes.bool,
  onClose: PropTypes.func,
  playerMetadata: PropTypes.object
};

export default EndModal;
