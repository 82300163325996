import React from 'react';
import Modal from '../../shared/modal/Modal';

const DelayModal = () => {
  const getHeaderText = () => {
    return 'HANG ON';
  };

  const getBodyText = () => {
    return <p>Communicating with server...</p>;
  };

  return <Modal headerContent={getHeaderText()} bodyContent={getBodyText()} />;
};

export default DelayModal;
